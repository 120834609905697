import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: "ajson", pure: false })
export class AdvancedJSONPipe implements PipeTransform {
    transform(value: any, level: number = 1): any {
        if (level === 0) {
            return JSON.stringify(value);
        } else {
            return JSON.stringify(value, null, "\t");
        }
    }
}
