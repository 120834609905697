import { enum_ConstraintType } from "../_enums/constraintType.enum";
import { ConstraintModel } from "./constraint.model";

export class InputModel extends ConstraintModel {
    value: any;
    type: string;

    constructor(obj: any) {
        super();

        if (obj.hasOwnProperty('value')) {
            this.value = obj.value;
        }

        if (obj.hasOwnProperty('type')) {
            this.type = obj.type;
        }

        if (obj.hasOwnProperty('constraintType')) {
            this.constraintType = obj.constraintType;
        }
        else {
            this.constraintType = enum_ConstraintType.Static;
        }

        if (obj.hasOwnProperty('min')) {
            this.min = obj.min;
        }

        if (obj.hasOwnProperty('max')) {
            this.max = obj.max;
        }

        if (obj.hasOwnProperty('default')) {
            this.default = obj.default;
        }
    }
}
