import { enum_ConstraintType, enum_ConstraintDataType } from "../_enums/constraintType.enum";

export abstract class ConstraintModel {

    constraintType: enum_ConstraintType = enum_ConstraintType.Static;
    constraintDataType: enum_ConstraintDataType = enum_ConstraintDataType.Number;
    min: any;
    max: any;
    default: any;
    updated: boolean = false;

    getMin = function (...args: any[]) {
        if (this.min == null || this.min == undefined)
            return;

        if (this.constraintDataType == enum_ConstraintDataType.Date) {
            return new Date(this.min);
        }
        else {
            switch (typeof this.min) {
                case 'number':
                    return this.min;
                case 'function':
                    return this.min(...args);
            }
        }
    };

    getMax = function (...args: any[]) {
        if (this.max == null || this.max == undefined)
            return;

        if (this.constraintDataType == enum_ConstraintDataType.Date) {
            return new Date(this.max);
        }
        else {
            switch (typeof this.max) {
                case 'number':
                    return this.max;
                case 'function':
                    return this.max(...args);
            }
        }
    };

    getDefault = function (...args: any[]) {
        if (this.default == null || this.default == undefined)
            return;

        if (this.constraintDataType == enum_ConstraintDataType.Date) {
            return new Date(this.default);
        }
        else {
            switch (typeof this.default) {
                case 'number':
                    return this.default;
                case 'function':
                    return this.default(...args);
            }
        }
    };
}
