import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'arialiveBinding'
})
export class ArialiveBindingPipe implements PipeTransform {

    transform(numberOfAriaLiveSpaces: number, language: string): any {
        if (numberOfAriaLiveSpaces === null) return '&nbsp;';
        if (numberOfAriaLiveSpaces === undefined) return '&nbsp;&nbsp;';
        if (numberOfAriaLiveSpaces === 0) return '&nbsp;&nbsp;&nbsp;';
        numberOfAriaLiveSpaces = Math.abs(numberOfAriaLiveSpaces);
        let livetriggerSpaces = "";
        for (let i = 0, len = numberOfAriaLiveSpaces; i < len; i++) {
            livetriggerSpaces += '&nbsp;';
        }
        return livetriggerSpaces;
    }

}
