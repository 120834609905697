import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'readerNumberLevel'
})
export class NumberLevelReaderPipe implements PipeTransform {

    transform(value: any, language: string): any {
        if (!value) return value;
        if (language === "zh") return value;
        if (language === "en") {
            let lastCharacter = value.toString().slice(-1);
            let lastCharacterNumber = parseFloat(lastCharacter);
            if (lastCharacterNumber === 1) {
                return "1st";
            }
            else if (lastCharacterNumber === 2) {
                return "2nd";
            }
            else if (lastCharacterNumber === 3) {
                return "3rd";
            }
            else {
                return value.toString() + 'th';
            }
        }
        else {
            return value;
        }
    }

}
