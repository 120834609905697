import { Injectable } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { Subject, Observable } from "rxjs";
import { ClientModel, IViewStates } from "../_models/client.model";

import { environment } from '../../environments/environment';

@Injectable()
export class InputsService {

    constructor() {
        //console.debug("InputsService().constructor()");
    }

    createOptionsFromRange(min: number, max: number, step: number = 1, prefix: string = '', suffix: string = '') {
        if (typeof (min) === "string") {
            min = parseFloat(min);
        }
        if (typeof (max) === "string") {
            max = parseFloat(max);
        }

        let options = [];
        let i: number = min;
        do {
            let option = {
                label: prefix + i.toString() + suffix,
                value: i
            };
            options.push(option);
            i += step;
        } while (i <= max);

        return options;
    }

}
