import { formatNumber } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'short1000s'
})
export class ShortThousandsPipe implements PipeTransform {
    transform(number: number, isAppendingPowerKey: boolean = true): any {
        if (isNaN(number)) return null; // will only work value is a number
        const isNegative = number < 0; // will also work for Negetive numbers
        let abs = Math.abs(number);
        let key = 'K';
        let valueThousands = abs/1000;
        let isDecimal = valueThousands.toString().indexOf(".") !== -1;
        return (isNegative ? '-' : '') + (isDecimal ? formatNumber(parseFloat(valueThousands.toFixed(1)), 'en-US') : formatNumber(parseFloat(valueThousands.toFixed(0)), 'en-US')) + (isAppendingPowerKey ? key : '');
    }
}
