import { Pipe, PipeTransform } from '@angular/core';
import * as Handlebars from 'handlebars/dist/cjs/handlebars';

@Pipe({
  name: 'hrenderer'
})
export class HandlebarsRendererPipe implements PipeTransform {
  transform(template: string, templateData?: any): any {
    const hTemplate = Handlebars.compile(template);
    return hTemplate(templateData);
  }
}
