import { Pipe, PipeTransform } from '@angular/core';

/** Pipe handles constraints for the Work Roles Start In dropdown  */
@Pipe({ 'name': 'incomeAgeToSlicedOptions', pure: false })
export class IncomeAgeToSlicedOptionsPipe implements PipeTransform {
    transform(completeOptions: any, fromAge: any, toAge: any) {
        if (completeOptions === null || completeOptions === undefined) {
            return completeOptions;
        }
        if (fromAge === null || fromAge === undefined) {
            return completeOptions;
        }
        return completeOptions.filter((item) => item > parseInt(fromAge) && item <= parseInt(toAge));
    }
}
