import { Component } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
import { Subscription } from 'rxjs';
import { GlobalService } from './_services/';

// LIST OF 3rd Party Dependencies
// https://www.npmjs.com/package/ngx-bootstrap-slider

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css']
})
export class AppComponent {
    title = 'app';
    _globalService: GlobalService;
    _subscriptionRouterEvents: Subscription = null;

    constructor(private globalService: GlobalService, private router: Router) {
        console.debug('AppComponent().construcator()');
        this._globalService = globalService;

        this._subscriptionRouterEvents = this.router.events.subscribe(event => {
            if (event instanceof NavigationStart) {
                if (this._globalService.isError) {
                    this.reload();
                }
            }
        });
    }

    reload() {
        console.debug('AppComponent().reload()');
        let loc = window.location.origin;
        let site = window.location.pathname;
        if (site != null && site != '') {
            let siteName = site.split('/').find(x => x != '');
            if (siteName != null && siteName != '') {
                loc = loc + '/' + siteName;
            }
        }

        window.location.href = loc + "/?lang=" + this.globalService.currentLanguage;
    }
}
