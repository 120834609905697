import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'pluralize'
})
export class PluralizePipe implements PipeTransform {

    transform(value: string, count: number, language: string, pluralCharacter: string = 's'): any {
        if (!value) return value;
        if (language === "zh") return value;
        if (language === "en") {
            if (count === 0 || count > 1) {
                return value + pluralCharacter;
            }
            else {
                return value;
            }
        }
        else {
            return value;
        }
    }

}
