export class GenericEvent {
    name: string = null;
    data: any = null;
    constructor(name: string, data?: any) {
        this.name = name;
        this.data = data ? data : null;
    }
}

export enum enum_GenericEvents {
    'SELECTED_LIFESTYLE_OPTION_CHANGED' = 'Selected lifestyle option changed.',
    'UPDATE_CONTENT' = 'updateContent',
    'UPDATED_STEP1_TARGETYEARS' = 'updatedStep1TargetYears',
    'UPDATED_SPENDING_PERSONALIZED' = 'updatedSpendingPersonalized',
    'UPDATE_SUBHEADER_REGULARSPENDING' = 'updateSubheaderRegularSpendingDisplay',
    'FOCUS_STARTUP_SELECTED_DISPLAY' = 'focusStartupSelectedDisplay',
    'SCROLL_TO_VIEW_SUBHEADER' = 'scrollToViewSubheader',
    'CLOSE_HAMBURGER_MENU_SIDEBAR' = 'closeHamburgerMenuSidebar',
    'OPEN_PERSONALISED_MODAL'= 'openPersonalisedModal',
    'EDITABLE_HTML_SELECTED' = "editableHtmlSelected",
    'ADDED_OTHER_SPENDING_ITEM' = "addedOtherSpendingItem",
    'FOCUS_FIRST_OTHER_SPENDING_ITEM' = "focusFirstOtherSpendingItem",
    'UPDATE_CHARTDATA_FROM_RESULTS' = "updateChartdataFromResults",
}
