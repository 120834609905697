import { Pipe, PipeTransform } from "@angular/core";

// CONVERTS ARRAYS TO DICTIONARY
@Pipe({ name: "arrdict", pure: false })
export class ArrdictPipe implements PipeTransform {
    transform(array, dictionaryKeyProp: string): any {
        if (array === null || array === undefined) {
            return array;
        }
        return array.reduce((obj, arrayItem) => {
            obj[arrayItem[dictionaryKeyProp]] = arrayItem;
            return obj;
        }, {});
    }
}
