import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { ClientModel, IViewStates } from "../_models/client.model";
import { enum_GenericEvents } from "../_enums/globalEvents.enum";

export enum SCENARIOS {
    HIT_SECURE = 1,
    NO_HIT_SECURE = 2,
    HIT_NOT_SECURE = 3,
    NO_HIT_NOT_SECURE = 4
}
export enum RP_SCENARIOS {
    HIT = 1,
    NO_HIT = 2,
}


@Injectable()
export class GlobalService {
    //*********** use these props if we are using dummy/local data **********
    //  private rpCurrentScenario:number = 1;
    //  private pretCurrentScenario:number = 1;
    //*********************************************************** */

    isError: boolean;
    isLoadingApp: boolean;
    isInvalid: boolean = false;
    isDisclaimerApproved: boolean = false;
    isSoA: boolean = false;
    cCount: number = 0;
    currentModal: string = null;

    viewStates: IViewStates = {
        currentStep: 1,
        activeLifestyleOption: 'comfortable'
    };

    currentLanguage: string = "en";
    currentCountry: string = "hk";

    // observable stream source
    genericEventSource = new Subject<any>();
    genericEvent$ = this.genericEventSource.asObservable();

    clientCalculator: any;

    clientModel: ClientModel;
    private numberOfOpenedModals:number = 0;
    private _modalListener:Subject<number> = new Subject();
    public modalListener:Observable<number> = this._modalListener;

    onModalOpen(modalName:string){
        this.numberOfOpenedModals = this.numberOfOpenedModals + 1;
        this._modalListener.next(this.numberOfOpenedModals);
    }

    onModalClose(modalName:string){
         this.modalClosedDebounced();
    }

    modalClosedDebounced = this.debounce(()=>(()=>{
            this.numberOfOpenedModals = this.numberOfOpenedModals - 1;
            if(this.numberOfOpenedModals == 0){
                this._modalListener.next(this.numberOfOpenedModals);
            }
    })())

    debounce(func, timeout = 150){
        let timer;
        return (...args) => {
            clearTimeout(timer);
            timer = setTimeout(() => { func.apply(this, args); }, timeout);
        };
    }

    createAlphaNumericID(length) {
        let result = '';
        let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let charactersLength = characters.length;
        for (let i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    }

    // GLOBAL EVENTS
    dispatchEventScrollToSubheader() {
        let scrollToSubheaderEvent = {};
        scrollToSubheaderEvent[enum_GenericEvents.SCROLL_TO_VIEW_SUBHEADER] = true;
        this.genericEventSource.next(scrollToSubheaderEvent);
    }

    dispatchEventCloseMenuSideBar() {
        let closeHamburgerMenuEvent = {};
        closeHamburgerMenuEvent[enum_GenericEvents.CLOSE_HAMBURGER_MENU_SIDEBAR] = true;
        this.genericEventSource.next(closeHamburgerMenuEvent);
    }

    constructor() {
        //console.debug("GlobalService().constructor()");
        // set up event trigger for window.resize

        this.isError = false;
        this.isLoadingApp = false;

        this.clientModel = new ClientModel({});

        let timerWindowResize;
        let self = this;
        window.onresize = () => {
            // clear timer if already set
            clearTimeout(timerWindowResize);

            // set timer
            timerWindowResize = setTimeout(function () {
                self.triggerGenericEvent("window.resize()");
            }, 250);
        };
    }

    triggerGenericEvent(event: any) {
        this.genericEventSource.next(event);
    }

    /***** * Use the below if we are using the api *****/
    get pret_currentScenario(): SCENARIOS {
        if(this.invOption === 'secure'){
            if(this.certaintyDiff >= 0) {
                return SCENARIOS.HIT_SECURE;
            }else{
                return SCENARIOS.NO_HIT_SECURE;
            }
        }else{
            if(this.certaintyDiff >= 0){
                return SCENARIOS.HIT_NOT_SECURE;
            }else{
                return SCENARIOS.NO_HIT_NOT_SECURE;
            }
        }
    }
    get rp_currentScenario():RP_SCENARIOS {
        // return 1;
         if (this.savings <= 0)
         {
             return RP_SCENARIOS.HIT;
         }
         else {
             return RP_SCENARIOS.NO_HIT;
         }
    }
    /***************************************** */

     //*********** use the below if we are using dummy/local data **********

    // get rp_currentScenario():number {
    //     return this.rpCurrentScenario;
    // }

    // set rp_currentScenario(value:number){
    //     this.rpCurrentScenario = value;
    // }
    // get pret_currentScenario():number {
    //     return this.pretCurrentScenario;
    // }

    // set pret_currentScenario(value:number){
    //     this.pretCurrentScenario = value;
    // }
    //************************************** */ */



    get savings(): number {
        if (this.clientModel.results && this.clientModel.results['current'] && this.clientModel.results['current'].stochastic) {
            return (this.clientModel.results['current'].stochastic.savings);
        }
        else {
            return 0;
        }
    }

    get invOption(): string {
         return this.clientModel.members['current'].spending.investmentOption;
    }

    get certaintyDiff(): number {
        if (this.clientModel.results && this.clientModel.results['current'] && this.clientModel.results['current'].stochastic) {
            return +((this.clientModel.results['current'].stochastic.pTarget - this.clientModel.members['current'].targetP).toFixed(2));
        }
        else {
            return 0;
        }
    }

    get certaintyDiffAbs(): number {
        return Math.abs(this.certaintyDiff);
    }
    get certaintyDiffInPercent():number{
        return +((this.certaintyDiffAbs * 100).toFixed(2))
    }

    scrollToDisplay(scrollToID) {
        setTimeout(() => {
            console.debug("\n\n[>] scrollToDisplay(scrollToID)");
            console.debug("[<] scrollToID: ", scrollToID);
            let el = document.getElementById(scrollToID);
            el.scrollIntoView({ behavior: 'smooth', block: 'center' });
        });
    }

    dispatchValidateEvent() {
        this.genericEventSource.next({ 'validate': true });
    }

    getLifestyles(activeOrLate: string = null) {
        if (
            this.clientModel &&
            this.clientModel.member &&
            this.clientModel.member.people &&
            this.clientModel.member.people[0] &&
            this.clientModel.member.spending &&
            this.clientModel.economic &&
            this.clientModel.economic.lifestyles &&
            this.clientModel.economic.lifestylesLate
        ) {
            if (this.clientModel.member.spending.willChangeLate) {
                if (activeOrLate === "Late") {
                    return this.clientModel.economic.lifestylesLate;
                }
                else {
                    return this.clientModel.economic.lifestyles;
                }
            }
            else {
                if (this.clientModel.member.people[0].age >= 80) {
                    return this.clientModel.economic.lifestylesLate;
                }
                else {
                    return this.clientModel.economic.lifestyles;
                }
            }
        }
        else {
            return null;
        }
    }
}
