import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'currencyReader'
})
export class CurrencyReaderPipe implements PipeTransform {

    transform(value: any, language: string): any {
        if (!value) return value;
        if (language === "en") return value;

        if (language === "zh") {
            return value.replace(/,/g, "");
        }
        else {
            return value;
        }
    }

}
