export enum enum_SectionName {
    "home" = "Home",
    "assumptions" = "Assumptions",
    "navHeader" = "NavHeader",
    "navFooter" = "NavFooter",
    "moneyToLast" = "MoneyToLast",
    "regularSpending" = "RegularSpendingComponent",
    "MODAL_REGULAR_SPENDING_COMPONENT" = "ModalRegularSpendingComponent",
    "spendingGoals" = "SpendingGoals",
    "ModalPersonalisedGoalitemsComponent" = "ModalPersonalisedGoalitemsComponent",
    "SliderLifestylegoalComponent" = "SliderLifestylegoalComponent",
    "MoneyToLastComponent" = "MoneyToLastComponent",
    "HowCertainResultsComponent" = "HowCertainResultsComponent",
    "HOW_CERTAIN_INPUTS_COMPONENT" = "HowCertainInputsComponent",
    "HOW_CERTAIN_COMPONENT" = "HowCertainComponent",
    "targetRetirementSavings" = "TargetRetirementSavings",
    "NEXT_STEPS_COMPONENT" = "NextStepsComponent",
    "LIFESTYLE_BASIC" = "Lifestyle_basic",
    "LIFESTYLE_MODEST" = "Lifestyle_modest",
    "LIFESTYLE_COMFORTABLE" = "Lifestyle_comfortable",
    "LIFESTYLE_AFFLUENT" = "Lifestyle_affluent",
    "LIFESTYLE_PERSONALISED" = "Lifestyle_personalised",
    "PIECHART_SNAIL_COMPONENT" = "PiechartSnailComponent",
    "RETIREMENT_SAVINGS_INPUTS_COMPONENT" = "RetirementSavingsInputsComponent"
}
