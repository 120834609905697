import { PdfService } from 'src/app/_services';
import { environment } from 'src/environments/environment';
import { saveAs } from 'file-saver';
import * as JSZip from 'jszip';

export class PdfModel {
    isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);

    constructor(private pdfService: PdfService) { }

    savePdf(filename: string, csv: any = null) {

        console.debug('PdfModel().savePdf()');

        this.pdfService.setPdfModel().subscribe(
            (response: any) => {

                let pdfQueueKey = response.body.pdfQueueKey;
                let responseBody = response.body;
                let clientCalculator = environment.calculator.clientCalculator;
                console.debug(`pdfQueueKey: ${pdfQueueKey}`);
                console.debug(`clientCalculator: ${clientCalculator}`);
                console.debug('responseBody:', responseBody);

                if (environment.switches.isTestingToolVisible) {
                    if (!this.isIEOrEdge) {
                        window.open(`/${clientCalculator}/soa?pdfQueueKey=${pdfQueueKey}`);
                    }
                } else {
                    console.debug(`/${clientCalculator}/soa?pdfQueueKey=${pdfQueueKey}`);
                }

                let filenameCSV1; // = 'HSBC-Retirement Savings Target Report_SpendingTable' + filename;
                let filenameCSV2; // = 'HSBC-Retirement Savings Target Report_SpendingTable' + filename;
                let filenameTXT; // = 'HSBC-Retirement Savings Target Report_ScreenFriendly' + filename;
                let filenamePDF; // = 'HSBC-Retirement Savings Target Report' + filename;
                let filenameZIP; // = 'HSBC-Retirement Savings Target Report' + filename;

                if (environment.calculator.clientCalculator === 'spendingplanner') {
                    filenameCSV1 = 'HSBC-Estimated Retirement Certainty Report_SpendingTable' + filename;
                    filenameCSV2 = 'HSBC-Estimated Retirement Certainty Report_IncomeTable' + filename;
                    filenameTXT = 'HSBC-Estimated Retirement Certainty Report_ScreenReaderFriendly' + filename;
                    filenamePDF = 'HSBC-Estimated Retirement Certainty Report' + filename;
                    filenameZIP = 'HSBC-Estimated Retirement Certainty Report' + filename;
                } else {
                    filenameCSV1 = 'HSBC-Retirement Savings Target Report_SpendingTable' + filename;
                    filenameCSV2 = 'HSBC-Retirement Savings Target Report_IncomeTable' + filename;
                    filenameTXT = 'HSBC-Retirement Savings Target Report_ScreenReaderFriendly' + filename;
                    filenamePDF = 'HSBC-Retirement Savings Target Report' + filename;
                    filenameZIP = 'HSBC-Retirement Savings Target Report' + filename;
                }

                this.pdfService.generate(pdfQueueKey).subscribe(
                    success => {

                        let soaTxtContainer = document.getElementById('soaTxtContainer');
                        const txtFileContent = soaTxtContainer ? new Blob(["\ufeff", ...soaTxtContainer.innerText], { type: 'text/plain;charset = utf-8' }) : null;
                        const csvSpending = csv['spending'] ? new Blob(["\ufeff"   , ...csv['spending']], { type: 'text/plain;charset = utf-8' }) : null;
                        const csvIncome   = csv['income']   ? new Blob(["\ufeff"   , ...csv['income']]  , { type: 'text/plain;charset = utf-8' }) : null;

                        let blobs = [csvSpending, csvIncome , txtFileContent, success];
                        const zip = JSZip();
                        blobs.forEach((blob, i) => {
                            if (blob) {
                                if (i === 0) {
                                    zip.file(filenameCSV1 + '.csv', blob);
                                }
                                if (i === 1) {
                                    zip.file(filenameCSV2 + '.csv', blob);
                                }
                                else if (i === 2) {
                                    zip.file(filenameTXT + '.txt', blob);
                                }
                                else if (i === 3) {
                                    zip.file(filenamePDF + '.pdf', blob);
                                }
                            }
                        });

                        zip.generateAsync({type: 'blob'}).then(zipFile => {
                            saveAs(zipFile, filenameZIP + '.zip');
                        });

                        // saveAs(success, filename);
                    },
                    failed => {
                        saveAs(failed, filenameZIP + '.zip');
                    }
                );

            },
            (response: any) => {
                console.error(response);
                this.pdfService.isGeneratingSoA = false;
            }
        );
    }
}
