import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: "lifestylearraypipe", pure: false })
export class LifeStyleArrayPipe implements PipeTransform {

    transform(value: any, selectedGoal: string): any {
        let returnValue = [];
        for (let i of value) {
            let goalValue = i.value.filter(function (o) { return o.name == selectedGoal });
            if (goalValue != null) {
                returnValue.push({ 'key': i.key, 'amount': goalValue[0].amount });
            }
        }
        return returnValue;
    }
}
