import { Pipe, PipeTransform } from "@angular/core";

@Pipe({ name: "interpolate", pure: false })
export class InterpolatePipe implements PipeTransform {
    transform(value: any, args: any) {
        if (value === null || value === undefined ) return value;
        //console.log(args);
        value = value.replace(/{{([^}}]+)?}}/g, ($1, $2) =>
            $2.split(".").reduce((p, c) => (p ? p[c] : ""), args)
        );

        return value;
    }
}
