import { Injectable } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { GlobalService } from './global.service';
import { Location } from '@angular/common';

@Injectable()
export class NavigationService {

    private _routes: Array < any > = null;
    tabbingDebouncerTimeout: any = null;
    private _currentStep: number;

    constructor(
        private globalService: GlobalService,
        private route: ActivatedRoute,
        private router: Router,
        private location: Location
    ) {
        console.debug('NavigationService().constructor()');
    }

    initRoutes(routeConfigChildren) {
        let rcc = [];
        for (let i = 0, len = routeConfigChildren.length; i < len; i++) {
            let item = routeConfigChildren[i];
            if (item.path !== "" && item.path !== "." && item.path !== "/") {
                rcc.push(item);
            }
        }
        this._routes = rcc;
        this.updateRouteIndexes();
    }

    updateRouteIndexes() {
        console.debug('\n\n1. updateRouteIndexes() ---------- navigation.service 2020-07-16_15:00:00 ----------');
        console.debug('2. this._routes: ', this._routes);

        let routeIndexes = {};
        for (let i = 0, len = this._routes.length; i < len; i++) {
            let item = this._routes[i];
            if (item.path !== "" && item.path !== "/" && item.path !== "**") {
                if (item.hasOwnProperty('data')) {
                    routeIndexes[item.path] = item.data.hasOwnProperty('step') ? item.data.step : i;
                }
                else {
                    routeIndexes[item.path] = i;
                }
            }
        }
        this.globalService.clientModel.navigationModel.routeIndexes = routeIndexes;

        console.debug('3. routeIndexes: ', routeIndexes);
        console.debug('4. this.globalService.calculatorModel.navigationModel.routeIndexes: ', this.globalService.clientModel.navigationModel.routeIndexes);
    }

    get routes(): any {
        return this._routes;
    }

    getRoutePrevious(currentStep: number, device: string = 'all') {
        let previousStep = currentStep - 1;
        let previousRoute = this.routes.find((item) => {
            if (item.hasOwnProperty('data')) {
                return item.data.step === previousStep && item.data.device.indexOf(device) !== -1;
            } else {
                return false;
            }
        });
        if (!previousRoute) {
            return this.getRoutePrevious(previousStep, device);
        } else {
            return previousRoute;
        }
    }

    getRouteNext(currentStep: number, device: string = 'all') {
        let nextStep = currentStep + 1;
        let nextRoute = this.routes.find((item) => {
            if (item.hasOwnProperty('data')) {
                return item.data.step === nextStep && item.data.device.indexOf(device) !== -1;
            } else {
                return false;
            }
        });
        if (!nextRoute) {
            return this.getRouteNext(nextStep, device);
        } else {
            return nextRoute;
        }
    }

    goNext(currentStep: number, device: string = 'all') {
        let nextRoute = this.getRouteNext(currentStep, device);
        if (nextRoute) {
            this.goRoute('/' + this.globalService.clientCalculator.clientCalculator + '/' + nextRoute.path, nextRoute.path);
            this.scrollToTop();
        }
    }

    goBack(currentStep: number, device: string = 'all') {
        let previousRoute = this.getRoutePrevious(currentStep, device);
        if (previousRoute) {
            this.goRoute('/' + this.globalService.clientCalculator.clientCalculator + '/' + previousRoute.path, previousRoute.path);
            this.scrollToTop();
        }
    }

    goBackHistory() {
        this.location.back();
    }

    goRoute(route: string, path?: string, replaceUrl?: boolean) {
        //console.debug('\n\n1. goRoute(route: string, path: string) ---------- navigation.service 2020-06-12_21:53:24 ----------');
        //console.debug('2. route: ', route);
        //console.debug('3. path: ', path);
        if (!replaceUrl) {
            this.router.navigate([route]);
        } else {
            // this.router.navigate([route], {replaceUrl: true});
            this.router.navigate([route]);
        }

        this.scrollToTop();
    }

    set currentStep(step: number) {
        this._currentStep = step;
        if (this.globalService.clientModel) {
            this.globalService.clientModel.navigationModel.previousStep = this.globalService.clientModel.navigationModel.currentStep;
            this.globalService.clientModel.navigationModel.currentStep = this._currentStep;
        }
    }
    get currentStep() {
        return this._currentStep;
    }

    scrollToTop() {
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    }

}
