import { TealiumUtagService } from '../_services/utag.service';

export class uTagModel {

    static tealium: TealiumUtagService

    static setConfig(obj: TealiumUtagService, account: string, profile: string, environment: string, calculator: string, pageTitle: string) {
        console.debug('uTagModel().setConfig()');
        this.tealium = obj;
        this.tealium.setConfig({
            account: account,
            profile: profile,
            environment: environment
        });

        //track page view
        let pageName = 'pws:' + window.location.hostname + ':' + calculator + ':' + pageTitle
        pageName = pageName.replace(/-/g, ' ');
        this.tealium.view({
            event_name: 'init',
            page_name: pageName,
            page_category: window.location.hostname.replace(/-/g, ' '),
            site_section: 'pws',
            page_subcategory: calculator,
            page_url: window.location.host + window.location.pathname,
            raw_datalayer: calculator == 'targetplanner' ? '4987v1' : '4987v9'
        });
    }

    static trackPageView(obj?: any) {
        //if (obj == null || obj == undefined) {
        //    return;
        //}
        //console.debug('uTagModel().trackPageView()');
        //if (obj != null && obj != undefined) {
        //    console.debug(JSON.stringify(obj, null, 3));
        //}
        this.tealium.view(obj);
    }

    static trackEventView(obj?: any) {
        if (obj == null || obj == undefined) {
            return;
        }
        console.debug('uTagModel().trackEventView()');
        //if (obj != null && obj != undefined) {
        //    console.debug(JSON.stringify(obj, null, 3));
        //}
        this.tealium.link(obj);
    }
}
