import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from "@angular/core";
import { ActivatedRoute, Router } from '@angular/router';
import * as CryptoJS from 'crypto-js';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import LocalContentSpendingPlanner from 'src/app/spendingplanner/data/spendingplanner.dummy.content.json';
import LocalContentTargetPlanner from 'src/app/targetplanner/data/targetplanner.dummy.content.json';
import { environment } from '../../environments/environment';
import { enum_GenericEvents, GenericEvent } from '../_enums';
import { enum_ErrorMessages } from '../_enums/errorMessage.enum';
import { EditedContentModel, IEditedContentModel } from '../_models';
import { GlobalService } from "./global.service";
import DummyContentTargetPlanner from 'src/app/targetplanner/data/targetplanner.dummy.content.json';
import DummyContentSpendingPlanner from 'src/app/spendingplanner/data/spendingplanner.dummy.content.json';

@Injectable()
export class ContentsService {

    private headers: HttpHeaders;
    isLoadingContent = false;
    selectedLanguage: string = "en";

    constructor(
        private httpClient: HttpClient,
        private globalService: GlobalService,
        private router: Router,
        private route: ActivatedRoute
    ) {
        console.debug("ContentsService().constructor()");
    }

    private setHeaders(method: string, url: string) {
        if ((environment.calculator.apiKey == null || environment.calculator.apiKey === undefined) || (environment.calculator.clientId == null || environment.calculator.clientId === undefined)) {
            return;
        }

        let tMethod = method;
        let tDateStamp = new Date();
        let tClientApiKey = environment.calculator.apiKey;
        let tUrl = url;

        let tContentType = (tMethod === 'GET') ? '' : 'application/json;charset=UTF-8';
        let ct = tMethod + tUrl + tContentType + tDateStamp.toUTCString().replace('UTC', 'GMT');
        let ctSHA256 = CryptoJS.HmacSHA256(ct, tClientApiKey);
        let ctBase64 = CryptoJS.enc.Base64.stringify(ctSHA256);

        this.headers = new HttpHeaders({
            'X-TW-API-CLIENTID': environment.calculator.clientId,
            'X-TW-DATE': tDateStamp.toUTCString().replace('UTC', 'GMT'),
            'X-TW-SIGNATURE': ctBase64,
            'Content-Type': tContentType
        });
    }

    private loadContent(selectedCountry: string, selectedLanguage: string) {
        //console.debug('ContentsService().loadContent()');

        this.selectedLanguage = selectedLanguage;

        let turl = (environment.switches.useDummyContent) ? environment.urlsStatic.content : (environment.urls.base + environment.urls.content).format(this.globalService.clientCalculator.clientCalculator, selectedLanguage);
        if (location.origin.includes('post-retirement') || location.origin.includes('retirement-planning')) {
            let apiUri = location.origin + '/api';
            turl = turl.replace(environment.urls.base, apiUri);
        }
        let isSoA = window.location.href.indexOf('soa') !== -1;
        this.setHeaders('GET', turl);

        return this.httpClient.get(turl, { headers: this.headers, observe: 'response' }).pipe(catchError((err) => { if (environment.switches.isErrorTrapActive && !isSoA) { this.globalService.isError = true; }; return this.handleError(err); }));
    }

    getContentFromSection(contentsArray, section) {
        if (contentsArray) {
            let content = contentsArray.find(c => c.section === section);
            if (content) {
                return content;
            } else {
                return null;
            }
        } else {
            return null;
        }
    }

    getContentsDictionary(contentsArray) {
        console.debug('ContentsService().getContentsDictionary()');
        return contentsArray.reduce((obj, content) => {
            if (content.itemId !== '') {
                obj[content.section + "_" + content.itemId] = content;
            }
            else {
                obj[content.section] = content;
            }
            return obj;
        }, {});
    }

    updateContents(contents: any, selectedCountry: string, selectedLanguage: string) {
        //console.debug('ContentsService().updateContents()');

        if (!contents) return;
        if (environment.switches.useDummyContent) {
            if (this.router.url.indexOf('targetplanner') !== -1) {
                this.globalService.clientModel.contents = this.getContentsDictionary(LocalContentTargetPlanner[selectedLanguage]);
            }
            else {
                this.globalService.clientModel.contents = this.getContentsDictionary(LocalContentSpendingPlanner[selectedLanguage]);
            }
            this.globalService.genericEventSource.next({ 'updateContent': true });
        }
        else {
            this.globalService.clientModel.contents = this.getContentsDictionary(contents);
            this.globalService.genericEventSource.next({ 'updateContent': true });
        }
        this.isLoadingContent = false;
        this.selectedLanguage = selectedLanguage;
    }

    refreshContent(selectedCountry: string, selectedLanguage: string) {
        //console.debug('ContentsService().refreshContent()');
        let dummyContent;
        if(environment.switches.useDummyContent){
            if (this.globalService.clientCalculator.clientCalculator === "targetplanner") {
                dummyContent = DummyContentTargetPlanner;
            }
            else {
                dummyContent = DummyContentSpendingPlanner;
            }
            this.globalService.clientModel.contents = this.getContentsDictionary(dummyContent[selectedLanguage]);
            this.globalService.genericEventSource.next({ 'updateContent': true });
        }else{
            this.isLoadingContent = true;
            this.loadContent(selectedCountry, selectedLanguage).subscribe(
                (response) => {
                    this.updateContents(response.body, selectedCountry, selectedLanguage);
                },
                (error) => {
                    this.isLoadingContent = false;
                }
            );
        }
    }

    transformContentsToArray(contentsObj) {
        let contentsArray = [];
        for (const section in contentsObj) {
            if (Object.prototype.hasOwnProperty.call(contentsObj, section)) {
                const sectionItem = contentsObj[section];
                contentsArray.push(sectionItem);
            }
        }
        return contentsArray;
    }

    transformContentsNewToOld(contents) {
        if (contents === null || contents === undefined) return null;
        let oldContents = {};
        for (const section in contents) {
            if (Object.prototype.hasOwnProperty.call(contents, section)) {
                const contentNew = contents[section];
                let contentOld = {
                    misc: contentNew.elements,
                    fields: {}
                }
                oldContents[section] = contentOld;
            }
        }
        return oldContents;
    }

    transformContentsOldToNew(contents) {
        if (contents === null || contents === undefined) return null;
        let newContents = {};
        for (const section in contents) {
            if (Object.prototype.hasOwnProperty.call(contents, section)) {
                const contentOld = contents[section];
                let contentNew = {
                    section: section,
                    itemId: "",
                    country: "AU",
                    lang: "en",
                    elements: contentOld['misc'],
                    id: ""
                };
                newContents[section] = contentNew;
            }
        }
        return newContents;
    }

    updateContentItemHtmlTemplate(event: EditedContentModel, content: any) {
        if (event.isEditingInline) {
            console.debug('\n\n1. updateContentItemHtmlTemplate ---------- page.component 2020-07-23_13:23:36 ----------');
            console.debug('2. event: ', event);
            content.elements[event.elementKey] = event.htmlTemplate;
            console.debug("3. ", event.elementKey + " html string template is now reflected on the app's contents data.\nIt will be part of the devtool data when it's exported.");
        } else {
            event.content = content;
            this.globalService.triggerGenericEvent(new GenericEvent(enum_GenericEvents.EDITABLE_HTML_SELECTED, event));
        }
    }

    updateHtmlTemplate(iecm: IEditedContentModel) {
        this.globalService.triggerGenericEvent(new GenericEvent(enum_GenericEvents.EDITABLE_HTML_SELECTED,
            new EditedContentModel(iecm.elementKey, iecm.htmlTemplate, false, iecm.content)
        ));
    }

    private handleError(error: HttpErrorResponse) {
        //console.debug('ContentsService().handleError()');

        if (error.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            console.error('An error occurred:', error.error.message);
        } else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            console.error(
                `Backend returned code ${error.status}, ` +
                `body was: ${error.error}`);
        }

        switch (error.status) {
            case 403:
                return throwError(enum_ErrorMessages.ERRORMESSAGE_403);
            case 404:
                return throwError(enum_ErrorMessages.ERRORMESSAGE_404);
            case 500:
                return throwError(enum_ErrorMessages.ERRORMESSAGE_500);
            default:
                return throwError(`${error.status} - ${error.error}`);
        }
    }

}
