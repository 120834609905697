export enum enum_ConstraintType {
    Static,
    Dynamic
}

export enum enum_ConstraintDataType {
    Number,
    Boolean,
    Date
}
