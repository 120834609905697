interface String {
  format(...replacements: string[]): string;
  formatTooltipLabel(...replacements: string[]):string;
}

if (!String.prototype.format) {
  String.prototype.format = function () {
    let args = arguments;
    return this.replace(/{(\d+)}/g, function (match, number) {
      return typeof args[number] !== 'undefined'
        ? args[number]
        : match
        ;
    });
  };
}
if(!String.prototype.formatTooltipLabel){
  String.prototype.formatTooltipLabel = function(){
    let args = arguments;
    return  this.replace(/{(\d+)}/g, function (match, number) {
      return typeof args[number] !== 'undefined'
        ? args[number]
        : match;
    }).replace("?","").toLowerCase();
  }
}