import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'sortPieChartData'
})
export class SortPieChartDataPipe implements PipeTransform {
    transform(obj: any): any {
        if (obj === null || obj === undefined) return obj;
        return obj.sort((n1, n2) => n2.amount - n1.amount);
    }
}
