import { ConstraintService } from "../_services/constraint.service";

export { }

declare global {
    interface String {
        getMin(prop: string, ...args: any[]): number;
        getMax(prop: string, ...args: any[]): number;
        getDefault(prop: string, ...args: any[]): number;
        checkConstraint(prop: string, ...args: any[]): number;
    }
}

if (!String.prototype.checkConstraint) {
    String.prototype.checkConstraint = function (prop: string, ...args: any[]) {
        let _value = ConstraintService.checkConstraint(prop, parseFloat(this), ...args);
        return _value;
    }
}

if (!String.prototype.getMin) {
    String.prototype.getMin = function (prop: string, ...args: any[]) {
        let _min = ConstraintService.getMin(prop, ...args);
        return _min != null ? _min : this;
    }
}

if (!String.prototype.getMax) {
    String.prototype.getMax = function (prop: string, ...args: any[]) {
        let _max = ConstraintService.getMax(prop, ...args);
        return _max != null ? _max : this;
    }
}

if (!String.prototype.getDefault) {
    String.prototype.getDefault = function (prop: string, ...args: any[]) {
        let _default = ConstraintService.getDefault(prop, ...args);
        return _default != null ? _default : this;
    }
}

declare global {
    interface Number {
        getMin(prop: string, ...args: any[]): number;
        getMax(prop: string, ...args: any[]): number;
        getDefault(prop: string, ...args: any[]): number;
        checkConstraint(prop: string, ...args: any[]): number;
    }
}

if (!Number.prototype.checkConstraint) {
    Number.prototype.checkConstraint = function (prop: string, ...args: any[]) {
        let _value = ConstraintService.checkConstraint(prop, this, ...args);
        return _value;
    }
}

if (!Number.prototype.getDefault) {
    Number.prototype.getDefault = function (prop: string, ...args: any[]) {
        let _default = ConstraintService.getDefault(prop, ...args);
        return _default != null ? _default : this;
    }
}

if (!Number.prototype.getMin) {
    Number.prototype.getMin = function (prop: string, ...args: any[]) {
        let _min = ConstraintService.getMin(prop, ...args);
        return _min != null ? _min : this;
    }
}

if (!Number.prototype.getMax) {
    Number.prototype.getMax = function (prop: string, ...args: any[]) {
        let _max = ConstraintService.getMax(prop, ...args);
        return _max != null ? _max : this;
    }
}
