import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ 'name': 'SpendingGoalsFrequencyPipe', pure: false })
export class SpendingGoalsFrequencyPipe implements PipeTransform {
    transform(frequencyOptions: any, fromAge: number, toAge: number) {

        if (frequencyOptions == null)
            return frequencyOptions;

        let maxFreqency = 0;
        if (toAge - fromAge < 10) {
            maxFreqency = 0.2;
        }
        if (toAge - fromAge < 5) {
            maxFreqency = 0.25;
        }
        if (toAge - fromAge < 4) {
            maxFreqency = 0.333;
        }
        if (toAge - fromAge < 3) {
            maxFreqency = 0.5;
        }
        if (toAge - fromAge < 2) {
            maxFreqency = 1
        }
        if (toAge - fromAge == 0) {
            maxFreqency = 0;
        }

        return frequencyOptions.filter((item) => item.value >= maxFreqency || item.value == 0);

    }
}
