import { HttpClient, HttpErrorResponse, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from "@angular/core";
import { environment } from '../../environments/environment';
import { enum_ErrorMessages } from '../_enums/errorMessage.enum';
import { throwError } from 'rxjs';
import { catchError, finalize, map } from 'rxjs/operators';
import { GlobalService } from './global.service';
import { ApiService } from './api.service';
import { PdfModel } from '../_models';
import { CurrencyPipe, DatePipe } from '@angular/common';
import { CurrencyReaderPipe } from '../_pipes';
import { saveAs } from 'file-saver';
import * as JSZip from 'jszip';

@Injectable()
export class PdfService {

    public isGeneratingSoA: boolean = false;
    public chartCSVSpending: string = null;
    public chartCSVIncome: string = null;

    constructor(
        private httpClient: HttpClient,
        private globalService: GlobalService,
        private apiService: ApiService,
        private datePipe: DatePipe,
        private currencyPipe: CurrencyPipe,
        private currencyReaderPipe: CurrencyReaderPipe
    ) {
        //console.debug("PdfService().constructor()");
    }

    public print() {
        if (!this.isGeneratingSoA) {
            this.isGeneratingSoA = true;
            let pdfModel = new PdfModel(this);
            let dateTimeStamp = new Date();

            // let filename = `HSBC-SoA-${dateTimeStamp.getFullYear()}-${(dateTimeStamp.getMonth() + 1)}-${dateTimeStamp.getDate()}-${dateTimeStamp.getHours()}${dateTimeStamp.getMinutes()}${dateTimeStamp.getSeconds()}`;
            let filename = `-${dateTimeStamp.getFullYear()}-${(dateTimeStamp.getMonth() + 1)}-${dateTimeStamp.getDate()}-${dateTimeStamp.getHours()}${dateTimeStamp.getMinutes()}${dateTimeStamp.getSeconds()}`;

            let csv = {
                spending: null,
                income: null
            };

            if (environment.calculator.clientCalculator === 'spendingplanner') {
                csv.spending = this.getChartCSVSpending(
                    this.globalService.clientModel.contents.LikelihoodComponent,
                    this.globalService.clientModel.results.current.deterministic.spending,
                    this.globalService.clientModel.member
                );
                csv.income = this.getChartCSVIncome(
                    this.globalService.clientModel.contents.LikelihoodComponent,
                    this.getBarchartRetincomeSourcesDP(),
                    this.globalService.clientModel.member
                );
            } else {
                csv.spending = this.getChartCSVSpending(
                    this.globalService.clientModel.contents.TargetRetirementSavings,
                    this.globalService.clientModel.results.current.deterministic.spending,
                    this.globalService.clientModel.member
                );
                this.globalService.clientModel.testingModel.spendingTableMaxRowToPagebreak = 18;
            }

            // setTimeout(() => {
            //     let soaTxtContainer = document.getElementById('soaTxtContainer');
            //     const txtFileContent = soaTxtContainer ? new Blob(["\ufeff"  , ...soaTxtContainer.innerText]  , { type: 'text/plain;charset = utf-8' }): null;
            //     const csvSpending = csv['spending'] ? new Blob(["\ufeff"  , ...csv['spending']], { type: 'text/plain;charset = utf-8' }): null;
            //     const csvIncome   = csv['income']   ? new Blob(["\ufeff"  , ...csv['income']]  , { type: 'text/plain;charset = utf-8' }): null;
            //     let blobs = [csvSpending, csvIncome , txtFileContent];
            //     const zip = JSZip();
            //     blobs.forEach((blob, i) => {
            //         if (blob) {
            //             if (i === 0) {
            //                 zip.file(filename + '-1.csv', blob);
            //             }
            //             if (i === 1) {
            //                 zip.file(filename + '-2.csv', blob);
            //             }
            //             else if (i === 2) {
            //                 zip.file(filename + '.txt', blob);
            //             }
            //         }
            //     });
            //     zip.generateAsync({type: 'blob'}).then(zipFile => {
            //         saveAs(zipFile, filename + '.zip');
            //     });
            // }, 500);

            pdfModel.savePdf(filename, csv);
        }
    }

    getChartCSVSpending(content: any, data: any, member: any) {
        // MAPPING:
        // data = results?.current?.deterministic?.spending";
        // content = LikelihoodComponent;
        let h = {
            age: content.elements.ageTooltip.replace("{0}", ""),
            year: content.elements.yearTooltip.replace("{0}", ""),
            regularSpending: content.elements.label_regularSpending,
            medical: content.elements['label_medical'],
            agedCare: content.elements['label_agedCare'],
            travel: content.elements['label_travel'],
            car: content.elements['label_car'],
            legacy: content.elements['label_legacy'],
            leisure: content.elements['label_leisure'],
            renovation: content.elements['label_renovation'],
            other: content.elements['label_other'],
            total: content.elements['barchart_tooltip_total'],
        };
        let ccy = " (" + content.elements.ccy + ")";
        let csvData = `${h.age},${h.year},${h.regularSpending}${ccy},${h.medical}${ccy},${h.agedCare}${ccy},${h.travel}${ccy},${h.car}${ccy},${h.legacy}${ccy},${h.leisure}${ccy},${h.renovation}${ccy},${h.other}${ccy},${h.total}${ccy}\n`;

        for (let a = member.people[0].ageR; a <= member.targetAge; a++) {
            let r = this.getChartCSVRowDataSpending(a, data, content);
            csvData += `${r.age},${r.year},${r.regularSpending},${r.medical},${r.agedCare},${r.travel},${r.car},${r.legacy},${r.leisure},${r.renovation},${r.other},${r.total}\n`;
        }

        return csvData;

    }

    getChartCSVRowDataSpending(age: number, data: any, content: any) {

        let goalTags: string[] = ['medical', 'agedCare', 'travel', 'car', 'legacy', 'leisure', 'renovation', 'other'];

        let csvRowData = {
            age: "",
            year: "",
            regularSpending: "",
            medical: "",
            agedCare: "",
            travel: "",
            car: "",
            legacy: "",
            leisure: "",
            renovation: "",
            other: "",
            total: ""
        };

        console.debug('\n[>] getChartCSVRowDataSpending(age: number, data: any)');
        console.debug('[] - age', age);

        // console.log(age);

        let yearLookup = data[0].projection.find(p => p.intAge === age);

        console.debug('[] - yearLookup', yearLookup);

        if (yearLookup === undefined) {
            return null;
        }

        //construct list of items
        //first row is regular spending totaled
        let total = 0;
        let spendingTotal = 0;

        data.filter(c => c['item'].tag === "Active" || c['item'].tag === "Late" || c['item'].tag === "Other").forEach((dataItem => {
            let projectionData = dataItem.projection.find(p => p.intAge === age);
            if (dataItem['item'].tag === "Other") {
                total += projectionData.amountPV;
            }
            if ((dataItem['item'].tag === "Active" || dataItem['item'].tag === "Late")) {
                total += projectionData.amountPV;
                spendingTotal += projectionData.amountPV;
            }
        }));

        goalTags.forEach((goal) => {
            let gTotal = 0;
            data.filter(c => c['item'].tag === "Other" && c['item'].name.split(" ")[0] === goal).forEach((dataItem => {
                let projectionData = dataItem.projection.find(p => p.intAge == age);
                gTotal += projectionData.amountPV;
            }));
            // csvRowData[goal] = content.elements.ccy + this.currencyReaderPipe.transform(this.currencyPipe.transform(gTotal, 'HKD', '', '1.0-0'), content.lang);
            csvRowData[goal] = gTotal.toFixed(0);
        });

        csvRowData['age'] = age.toString();
        csvRowData['year'] = yearLookup.year.toString();
        // csvRowData['regularSpending'] = content.elements.ccy + this.currencyReaderPipe.transform(this.currencyPipe.transform(spendingTotal, 'HKD', '', '1.0-0'), content.lang);
        // csvRowData['total'] = content.elements.ccy + this.currencyReaderPipe.transform(this.currencyPipe.transform(total, 'HKD', '', '1.0-0'), content.lang);
        csvRowData['regularSpending'] = spendingTotal.toFixed(0);
        csvRowData['total'] = total.toFixed(0);

        return csvRowData;
    }

    getChartCSVIncome(content: any, data: any, member: any) {
        // MAPPING:
        // data = results?.current?.deterministic?.spending";
        // content = LikelihoodComponent;
        let h = {
            age: content.elements.ageTooltip.replace("{0}", ""),
            year: content.elements.yearTooltip.replace("{0}", ""),
            incomeAssets: content.elements['labelincome_incomeAssets'],
            incomeAnnuities: content.elements['labelincome_incomeAnnuities'],
            incomeProperty: content.elements['labelincome_incomeProperty'],
            drawdown: content.elements['labelincome_drawdown'],
            total: content.elements['barchart_tooltip_total']
        };
        let ccy = " (" + content.elements.ccy + ")";
        let csvData = `${h.age},${h.year},${h.incomeAssets}${ccy},${h.incomeAnnuities}${ccy},${h.incomeProperty}${ccy},${h.drawdown}${ccy},${h.total}${ccy}\n`;

        for (let a = member.people[0].ageR; a <= member.targetAge; a++) {
            let r = this.getChartCSVRowDataIncome(a, data, content, member);
            if (r) {
                csvData += `${r.age},${r.year},${r.incomeAssets},${r.incomeAnnuities},${r.incomeProperty},${r.drawdown},${r.total}\n`;
            }
        }

        return csvData;

    }

    getChartCSVRowDataIncome(age: number, data: any, content: any, member: any) {

        // DRAWDOWN: incomeYou + IncomePartner
        // INCOMES:
        // Family support = incomeAssets
        // Annuity = incomeAnnuities
        // Property = incomeProperty

        let incomeOthersTag: string[] = ['incomeAssets', 'incomeAnnuities', 'incomeProperty'];

        let csvRowData = {
            age: "",
            year: "",
            incomeAssets: "",
            incomeAnnuities: "",
            incomeProperty: "",
            drawdown: "",
            total: ""
        };

        console.debug('\n[>] getChartCSVRowDataIncome(age: number, data: any)');
        console.debug('[] - age', age);

        // console.log(age);

        let yearLookup = data.projection.find(p => p.intAge == age);

        console.debug('[] - yearLookup', yearLookup);

        if (yearLookup === undefined) {
            return null;
        }

        let otherIncomes = member.people[0].otherIncome;

        let includeFamilySupport = otherIncomes.filter((item) => item.name === 'FamilySupport').length !== 0;
        let includeAnnuity = otherIncomes.filter((item) => item.name === 'Annuity').length !== 0;
        let includeProperty = otherIncomes.filter((item) => item.name === 'Property').length !== 0;

        //construct top line

        let totalAll = 0;

        incomeOthersTag.forEach((incomeOtherTag) => {
            let total = 0;
            let projectionData_1 = data.projection.find(p => p.intAge == age);
            if (projectionData_1) {
                if (includeFamilySupport && incomeOtherTag === "incomeAssets") {
                    total += projectionData_1.incomeAssets;
                    totalAll += projectionData_1.incomeAssets;
                }
                if (includeAnnuity && incomeOtherTag === "incomeAnnuities") {
                    total += projectionData_1.incomeAnnuities;
                    totalAll += projectionData_1.incomeAnnuities;
                }
                if (includeProperty && incomeOtherTag === "incomeProperty") {
                    total += projectionData_1.incomeProperty;
                    totalAll += projectionData_1.incomeProperty;
                }
                // csvRowData[incomeOtherTag] = content.elements.ccy + this.currencyReaderPipe.transform(this.currencyPipe.transform(total, 'HKD', '', '1.0-0'), content.lang);
                csvRowData[incomeOtherTag] = total.toFixed(0);
            }
        });

        let totalDrawdown = 0;
        let projectionData = data.projection.find(p => p.intAge === age);

        totalDrawdown += projectionData.incomeYou;
        totalAll += projectionData.incomeYou;

        totalDrawdown += projectionData.incomePartner;
        totalAll += projectionData.incomePartner;

        csvRowData['age'] = age.toString();
        csvRowData['year'] = yearLookup.year.toString();

        // csvRowData['drawdown'] = content.elements.ccy + this.currencyReaderPipe.transform(this.currencyPipe.transform(totalDrawdown, 'HKD', '', '1.0-0'), content.lang);
        // csvRowData['total'] = content.elements.ccy + this.currencyReaderPipe.transform(this.currencyPipe.transform(totalAll, 'HKD', '', '1.0-0'), content.lang);
        csvRowData['drawdown'] = totalDrawdown.toFixed(0);
        csvRowData['total'] = totalAll.toFixed(0);

        return csvRowData;
    }

    getBarchartRetincomeSourcesDP() {

        console.debug("\n\n[] setBarchartRetincomeSourcesDP()");

        let barchartRetincomeSourcesDP = null;

        let projection = [];
        if (this.globalService.clientModel && this.globalService.clientModel.results && this.globalService.clientModel.results.current && this.globalService.clientModel.results.current.deterministic) {

            let summary = this.globalService.clientModel.results.current.deterministic.summary.projection;
            let otherIncomeProjections = this.globalService.clientModel.results.current.deterministic.people[0].otherIncomeProjections;

            let isAllValuesZero = !environment.switches.useRandomizedChartData ? true : false;

            let familySupports;
            let annuitys;
            let propertys;

            if (otherIncomeProjections && otherIncomeProjections.length !== 0) {
                familySupports = otherIncomeProjections.filter((iter) => iter.item.name === 'FamilySupport');
                annuitys = otherIncomeProjections.filter((iter) => iter.item.name === 'Annuity');
                propertys = otherIncomeProjections.filter((iter) => iter.item.name === 'Property');
            }

            for (let i = 0, len = summary.length; i < len; i++) {

                let summaryItem = summary[i];

                if (summaryItem.incomeAssets !== 0) {
                    isAllValuesZero = false;
                }

                let transformedChartDataProjectionItem = {
                    "incomeYou": summaryItem.incomeAssets || 0,
                    "incomePartner": 0,
                    "year": summaryItem.year,
                    "age": summaryItem.age,
                    "intAge": summaryItem.intAge,
                    "indexation": 1,
                    "incomeAssets": 0, // FamilySupport
                    "incomeAnnuities": 0, // Annuity
                    "incomeProperty": 0 // Property
                };

                if (otherIncomeProjections && otherIncomeProjections.length !== 0) {

                    // let familySupports = otherIncomeProjections.filter((iter) => iter.item.name === 'FamilySupport');
                    if (familySupports.length !== 0) {
                        let totalFamilySupport = 0;
                        for (let j = 0, lenj = familySupports.length; j < lenj; j++) {
                            let familySupport = familySupports[j];
                            let familySupportProjectionItemAtIntAge = familySupport.projection.find((item) => item.intAge === summaryItem.intAge);
                            if (familySupportProjectionItemAtIntAge !== undefined && familySupportProjectionItemAtIntAge !== null) {
                                totalFamilySupport += familySupportProjectionItemAtIntAge.nonTaxableAmountPV;
                            }
                        }
                        if (totalFamilySupport !== 0 && totalFamilySupport !== null) {
                            isAllValuesZero = false;
                        }
                        transformedChartDataProjectionItem.incomeAssets = totalFamilySupport;
                    }

                    // let annuitys = otherIncomeProjections.filter((iter) => iter.item.name === 'Annuity');
                    if (annuitys.length !== 0) {
                        let totalAnnuity = 0;
                        for (let k = 0, lenk = annuitys.length; k < lenk; k++) {
                            let annuity = annuitys[k];
                            let annuityProjectionItemAtIntAge = annuity.projection.find((item) => item.intAge === summaryItem.intAge);
                            if (annuityProjectionItemAtIntAge !== undefined && annuityProjectionItemAtIntAge !== null) {
                                totalAnnuity += annuityProjectionItemAtIntAge.nonTaxableAmountPV;
                            }
                        }
                        if (totalAnnuity !== 0 && totalAnnuity !== null) {
                            isAllValuesZero = false;
                        }
                        transformedChartDataProjectionItem.incomeAnnuities = totalAnnuity;
                    }

                    // let propertys = otherIncomeProjections.filter((iter) => iter.item.name === 'Property');
                    if (propertys.length !== 0) {
                        let totalProperty = 0;
                        for (let l = 0, lenl = propertys.length; l < lenl; l++) {
                            let property = propertys[l];
                            let propertyProjectionItemAtIntAge = property.projection.find((item) => item.intAge === summaryItem.intAge);
                            if (propertyProjectionItemAtIntAge !== undefined && propertyProjectionItemAtIntAge !== null) {
                                totalProperty += propertyProjectionItemAtIntAge.nonTaxableAmountPV;
                            }
                        }
                        if (totalProperty !== 0 && totalProperty !== null) {
                            isAllValuesZero = false;
                        }
                        transformedChartDataProjectionItem.incomeProperty = totalProperty;
                    }
                }
                projection.push(transformedChartDataProjectionItem);
            }
            barchartRetincomeSourcesDP = { projection: projection, isAllValuesZero: isAllValuesZero };
        }
        return barchartRetincomeSourcesDP;
    }

    generate(pdfQueueKey: string) {
        //post https://hcbtas-u-pdfservice-asia-east.azurewebsites.net/pdf/GeneratePdfPost
        // The payload has a referredUrl and pdfQueuKey which is building the url of the html of this website 
        //that pdf generator uses to generate the pdf from 
        // response will be the generated pdf
        console.debug('PdfService().generatePdf()');
        let pdfDate = new Date();
        let tUrl = environment.urls.pdfServiceGenerate;
        if (environment.calculator.clientCalculator === 'targetplanner') {
            this.globalService.clientModel.custom.currentScenario = this.globalService.rp_currentScenario;
        } else {
            this.globalService.clientModel.custom.currentScenario = this.globalService.pret_currentScenario;
        }

        let todayDate: string = this.datePipe.transform(new Date(), 'dd-MM-y');
        let pdfFooter = "";
        if (this.globalService.clientModel.contents && this.globalService.clientModel.contents.AppComponent && this.globalService.clientModel.contents.AppComponent.elements.pdfFooter) {
            pdfFooter = this.globalService.clientModel.contents.AppComponent.elements.pdfFooter.format(todayDate);
        }

        let pdfHeader = '';
        if (this.globalService.clientModel.contents && this.globalService.clientModel.contents['PdfComponent'] && this.globalService.clientModel.contents['PdfComponent'].elements.pdfpageheader) {
            pdfHeader = this.globalService.clientModel.contents['PdfComponent'].elements.pdfpageheader;
        }

        if (this.globalService.currentLanguage === "zh") {
            pdfFooter = todayDate;
        }

        return this.httpClient.post(tUrl, {
            'referrerUrl': environment.urls.host + '/' + environment.calculator.clientCalculator + '/' + environment.calculator.soAUrl,
            'pdfTitle': '',
            'pdfDate': pdfDate.toUTCString(),
            'calculatorModel': null,
            'clientCode': environment.calculator.clientCode,
            'clientCalculator': environment.calculator.clientCalculator,
            'pdfFooter': pdfFooter,
            'pdfHeader': pdfHeader,
            'baseUri': environment.urls.host,
            'pdfQueueKey': pdfQueueKey,
            'minTimeout': 5,
            'marginLeftRight': 40,
            'marginTopBottom': 20,
            'pdfHeaderHeight': 30
        }, {
            responseType: 'blob'
        })
            .pipe(map((res) => {
                let blob = new Blob([res], {
                    type: 'application/pdf'
                });
                return blob;
            }))
            .pipe(catchError(this.handleError))
            .pipe(finalize(() => {
                //console.debug('PdfService().generatePdf() --> finalize');
                this.isGeneratingSoA = false;
            }));
    }

    getPdfModel(pdfQueueKey: string) {
        // get https://hcbtas-u-pdfservice-asia-east.azurewebsites.net/pdf/GetPDFModel?pdfQueueKey=2549a742-6e2e-4f3d-967e-0e80012480c9
        // get the pdf json string in response
  
        console.debug(`PdfService().getPdfModel(${pdfQueueKey})`);
        let pdfDate = new Date();
        let tUrl = environment.urls.pdfServiceGetPdfModel + '?pdfQueueKey=' + pdfQueueKey;

        return this.httpClient.get(tUrl, {
            observe: 'response'
        })
            .pipe(catchError(this.handleError))
            .pipe(finalize(() => {
                //console.debug('PdfService().getPdfModel() --> finalize');
                this.isGeneratingSoA = false;
            }));
    }

    setPdfModel() {
        // post https://hcbtas-u-pdfservice-asia-east.azurewebsites.net/pdf/SetPDFModel
        // and in response get the pdfQueueKey guid
        console.debug(`PdfService().setPdfModel()`);
        let pdfDate = new Date();
        let tUrl = environment.urls.pdfServiceSetPdfModel;

        let todayDate: string = this.datePipe.transform(new Date(), 'dd-MM-y');
        let pdfFooter = "";
        if (this.globalService.clientModel.contents && this.globalService.clientModel.contents.AppComponent && this.globalService.clientModel.contents.AppComponent.elements.pdfFooter) {
            pdfFooter = this.globalService.clientModel.contents.AppComponent.elements.pdfFooter.format(todayDate);
        }

        if (this.globalService.currentLanguage === "zh") {
            pdfFooter = todayDate;
        }
        if (environment.calculator.clientCalculator === 'targetplanner') {
            this.globalService.clientModel.custom.currentScenario = this.globalService.rp_currentScenario;
        } else {
            this.globalService.clientModel.custom.currentScenario = this.globalService.pret_currentScenario;
        }

        console.debug('\n\n[] setPdfModel() : pdfFooter ', pdfFooter);

        //this.globalService.clientModel.currentCountry = this.globalService.currentCountry;
        //this.globalService.clientModel.currentLanguage = this.globalService.currentLanguage;

        return this.httpClient.post(tUrl, {
            'referrerUrl': environment.urls.host + '/' + environment.calculator.clientCalculator + '/' + environment.calculator.soAUrl,
            'pdfTitle': 'Testing Title',
            'pdfDate': pdfDate.toUTCString(),
            'calculatorModel': {
                'currentCountry': this.globalService.currentCountry,
                'currentLanguage': this.globalService.currentLanguage,
                'members': this.globalService.clientModel.members,
                'results': this.globalService.clientModel.results,
                'lifeExpectancy': this.globalService.clientModel.lifeExpectancy,
                'custom': {
                    'currentScenario': this.globalService.clientModel.custom.currentScenario
                }
            },
            'clientCode': environment.calculator.clientCode,
            'pdfFooter': pdfFooter,
            'clientCalculator': environment.calculator.clientCalculator
        }, {
            observe: 'response'
        })
            .pipe(catchError(this.handleError))
            .pipe(finalize(() => {
                //console.debug('PdfService().setPdfModel() --> finalize');
                //this.isGeneratingSoA = false;
            }));
    }

    private handleError(error: HttpErrorResponse) {
        //console.debug('PdfService().handleError()');
        if (error.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            console.error('An error occurred:', error.error.message);
        } else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            console.error(
                `Backend returned code ${error.status}, ` +
                `body was: ${error.error}`);
        }

        switch (error.status) {
            case 403:
                return throwError(enum_ErrorMessages.ERRORMESSAGE_403);
            case 404:
                return throwError(enum_ErrorMessages.ERRORMESSAGE_404);
            case 500:
                return throwError(enum_ErrorMessages.ERRORMESSAGE_500);
            default:
                return throwError(`${error.status} - ${error.error}`);
        }
    }
}
