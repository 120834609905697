import { NavigationModel } from "./navigation.model";
import { TestingModel } from "./testing.model";

export class EditedContentModel {
    elementKey: string = null;
    htmlTemplate: any = null;
    isEditingInline: boolean = false;
    content: any = null;
    constructor(elementKey: string, htmlTemplate: string, isEditingInline?: boolean, content?: any) {
        this.elementKey = elementKey;
        this.htmlTemplate = htmlTemplate;
        if (isEditingInline !== undefined) {
            this.isEditingInline = isEditingInline;
        }
        if (content !== undefined) {
            this.content = content;
        }
    }
}

export interface IEditedContentModel {
    elementKey: string;
    htmlTemplate: any;
    content: any;
    isEditingInline?: boolean;
}

export interface ISidebarEmitterModel {
    isSidebarOpen: boolean;
    data?: any;
}

export interface IContent {
    company?: string;
    itemId?: string;
    id?: string;
    country: string;
    lang: string;
    section: string;
    elements: IContentElements;
}

export interface IContents {
    [dynamicElementProp: string]: IContent;
}

export interface IContentElements {
    [dynamicElementProp: string]: string;
}

export interface IViewStates {
    currentStep: number;
    activeLifestyleOption: string;
}

export interface ISpendingGoal {
    name: string;
    amount: number;
    freq: number;
    fromAge?: number;
    toAge?: number;
    tag?: string;
    selectedPersonalised?: string;
    modified: boolean;
}

export interface IUpdatedSpendingPersonalisedEvent {
    updatedSpendingPersonalized: boolean;
    selectedLifestyleKey: string;
    personalisedLifestyleGoals: Array<ISpendingGoal>;
    tag: string;
}

export interface ICustom {
    currentScenario: number;
}

export class ClientModel {

    isOtherGoalsInitialised: boolean = false;
    economic: any; // = DummyInit.economic;
    sectionElements: any; // = DummyInit.sectionElements;
    listItems: any; // = DummyInit.listItems;
    members: any; // = DummyInit.members;
    results: any; // = DummyInit.results;
    testingModel: TestingModel = new TestingModel();
    navigationModel: NavigationModel = new NavigationModel();
    annuity: any = null;
    lifeExpectancy: any = null;
    probabilityToLiveToAgeUpdated: boolean = false;
    custom: ICustom = {
        currentScenario: 1
    }

    contents: IContents = null;

    result: IResult;

    currentCountry: string;
    currentLanguage: string;

    constructor(obj: any) {
        if (obj === undefined || obj === null) {
            return;
        }
    }

    get member(): any {
        if (this.members) {
            return this.members['current'];
        }
        else {
            return null;
        }
    }

}

export class IMember {
    age?: number;
    ageR?: number;
    targetAge?: number;
    runOutAge?: number;
    lifeExpectancy?: number;
    aveLifeExpectancy?: number;
    people?: Array<any>;
}

export class IResult {
    someVariable?: number;
}


export class IGoal {
    name: string;
    amount: number;
    freq: number;
    fromAge: number;
    toAge: number;
    tag: string;
    modified: boolean;
    shouldShowError?: boolean;
    shouldShowWarning?: boolean;
}

// Generated by https://quicktype.io
export interface IMembers {
    [dynamicElementProp: string]: IMember;
}

