import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: "objkeys", pure: false })
export class ObjectKeysPipe implements PipeTransform {
  transform(value, excludedKeys?: string[]): any {
    let keys = [];
    for (let key in value) {
        if (value.hasOwnProperty(key)) {
            if (excludedKeys === null || excludedKeys === undefined) {
                keys.push({ key: key, value: value[key] });
            }
            else {
                if (excludedKeys.indexOf(key) === -1) {
                    keys.push({ key: key, value: value[key] });
                }
            }
        }
    }
    return keys;
  }
}
