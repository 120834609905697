import { InterpolatePipe } from './interpolate.pipe';
import { NgModule } from "@angular/core";
import { NumberLevelReaderPipe } from './number-level-reader.pipe';
import { PluralizePipe } from './pluralize.pipe';
import { ShortThousandsPipe } from './short-thousands.pipe';
import { ArialiveBindingPipe } from './arialive-binding.pipe';

@NgModule({
    declarations: [
        InterpolatePipe,
        NumberLevelReaderPipe,
        PluralizePipe,
        ShortThousandsPipe,
        ArialiveBindingPipe
   ],
    exports: [
        InterpolatePipe,
        NumberLevelReaderPipe,
        PluralizePipe,
        ShortThousandsPipe,
        ArialiveBindingPipe
    ]
})
export class CoreCustomPipesModule { }
