import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

export function getBaseUrl() {
    return document.getElementsByTagName('base')[0].href;
}

const providers = [
    { provide: 'BASE_URL', useFactory: getBaseUrl, deps: [] }
];

if (environment.production) {
    enableProdMode();
}

var appInsights: ApplicationInsights;
if (environment.appInsights.instrumentationKey != '') {
    appInsights = new ApplicationInsights({
        config: {
            instrumentationKey: environment.appInsights.instrumentationKey,
            enableDebug: environment.appInsights.enableDebug,
            enableAutoRouteTracking: true,
            loggingLevelConsole: 2
        }
    });
    appInsights.loadAppInsights();
    appInsights.trackPageView();
}

platformBrowserDynamic(providers).bootstrapModule(AppModule)
    .catch(err => console.log(err));

//export { renderModule, renderModuleFactory } from '@angular/platform-server';
