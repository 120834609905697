import { Injectable } from "@angular/core";
import { enum_ConstraintDataType, enum_ConstraintType } from "../_enums/constraintType.enum";
import { InputModel } from "../_models/input.model";

@Injectable()
export class ConstraintService {

    static constraintsModel: InputModel[] = [];
    static constraintsExclude = ['otherAssets', 'willTake', 'breakType', 'retirementDate'];

    static getNestedProp(obj, key) {
        return key.split(".").reduce((o, x) => {
            return (typeof o === "undefined" || o === null) ? o : o[x];
        }, obj);
    }

    static checkExists(prop: any): InputModel {
        let constraintModel = null;
        if (this.constraintsModel != null && (constraintModel = this.constraintsModel.find(x => x.type == prop)) != null) {
            return constraintModel;
        }
        return constraintModel;
    }
    static getDefault(prop: any, ...args: any[]) {
        let constraintModel = this.checkExists(prop);
        if (constraintModel != null) {
            return constraintModel.getDefault(...args);
        }
        return null;
    }

    static getMin(prop: string, ...args: any[]) {
        let constraintModel = this.checkExists(prop);
        if (constraintModel != null) {
            return constraintModel.getMin(...args);
        }
        return null;
    }

    static getMax(prop: any, ...args: any[]) {
        let constraintModel = this.checkExists(prop);
        if (constraintModel != null) {
            return constraintModel.getMax(...args);
        }
        return null;
    }

    static checkConstraint(prop: string, value: any, ...args: any[]): any {
        let constraintModel = this.checkExists(prop);
        let min = this.getMin(prop, ...args);
        let max = this.getMax(prop, ...args);

        console.debug(`checkConstraint(${prop}, ${value}) min: ${min}, max:${max}`);

        switch (constraintModel.constraintDataType) {
            case enum_ConstraintDataType.Date:
                if (new Date(value) < min) {
                    value = min.getFullYear() + '-' + (min.getMonth() + 1) + '-' + min.getDate();
                    constraintModel.updated = true;
                }
                else if (new Date(value) > max) {
                    value = max.getFullYear() + '-' + (max.getMonth() + 1) + '-' + max.getDate();
                    constraintModel.updated = true;
                }
                constraintModel.value = value;
                break;

            default:
                if (value < min) {
                    value = min;
                    constraintModel.updated = true;
                }
                else if (value > max) {
                    value = max;
                    constraintModel.updated = true;
                }
                constraintModel.value = value;
                break;
        }
        return value;
    }

    static parseDynamicConstraintObject(obj: any, pathName: string) {
        if (typeof (obj) == 'object') {

            //recursive call to read JSON object
            for (let child in obj) {
                if (ConstraintService.constraintsExclude.findIndex(x => x == child) == -1) { //check for skipping some properties
                    this.parseDynamicConstraintObject(obj[child], pathName + (pathName != '' ? '.' : '') + child);
                }
            }
        }
        else {

            //bind constraint
            let constraintName = pathName.substring(pathName.indexOf('.') + 1); //format name of constraint
            let currentConstraint = ConstraintService.constraintsModel.find(x => x.type == constraintName && x.constraintType == enum_ConstraintType.Dynamic); //get if existing
            if (currentConstraint == null) {
                currentConstraint = new InputModel({ type: constraintName, constraintType: enum_ConstraintType.Dynamic }); //create if not found

                //parse date
                if (obj.toString().indexOf('-') != -1 && (obj.match(/-/g) || []).length == 2) {
                    currentConstraint.constraintDataType = enum_ConstraintDataType.Date;
                }

                //parse boolean
                if (obj == 'true' || obj == 'True' || obj == 'false' || obj == 'False') {
                    currentConstraint.constraintDataType = enum_ConstraintDataType.Boolean;
                }

                ConstraintService.constraintsModel.push(currentConstraint); //add to constraints
            }

            switch (pathName.substring(0, (pathName.indexOf('.')))) {
                case 'default':
                    currentConstraint.default = obj;
                    currentConstraint.value = obj;
                    break;

                case 'min':
                    currentConstraint.min = obj;
                    break;

                case 'max':
                    currentConstraint.max = obj;
                    break;
            }
        }
    }

    static checkConstraintsDynamic(obj: any) {
        console.debug('CalculatorModel().checkConstraintsDynamic()');

        //accepts a member object that should have the same properties/names as dynamic constraints
        if (obj == null || obj == undefined) {
            return;
        }

        let dynamicConstraints = ConstraintService.constraintsModel.filter(x => x.constraintType == enum_ConstraintType.Dynamic);
        if (dynamicConstraints != null) {
            for (let dynamicConstraint of dynamicConstraints) {
                let objValue = eval('obj.' + dynamicConstraint.type);

                if (objValue != null) {

                    if (dynamicConstraint.constraintDataType != enum_ConstraintDataType.Boolean) {
                        let constrintCheckValue = ConstraintService.checkConstraint(dynamicConstraint.type, objValue);
                        if (constrintCheckValue != objValue) {
                            switch (dynamicConstraint.constraintDataType) {
                                case enum_ConstraintDataType.Date:
                                    eval('obj.' + dynamicConstraint.type + '="' + constrintCheckValue + '"');
                                    break;
                                case enum_ConstraintDataType.Number:
                                    eval('obj.' + dynamicConstraint.type + '=' + constrintCheckValue);
                                    break;
                            }
                        }
                    }
                    else {
                        console.error(`Unsupported dynamic constraint ${dynamicConstraint.type} of type: ${typeof (objValue)}`);
                    }
                }
                else {
                    console.error(` ${dynamicConstraint.type}:  objValue == null`);
                }
            }
        }
    }
}
