import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import * as CryptoJS from 'crypto-js';
import * as deepEqual from 'deep-equal';
import { Observable, throwError } from 'rxjs';
import { catchError, delay, finalize, tap } from 'rxjs/operators';
import { enum_ErrorMessages, enum_GenericEvents, GenericEvent } from 'src/app/_enums';
import { environment } from '../../environments/environment';
import { GlobalService } from './global.service';

import '../_interfaces/string.interface';

@Injectable()
export class ApiService {

    private headers: HttpHeaders;
    public isCalculating: boolean = false;
    public currentDataObj: any = null;
    public previouslifeExpectancyRequestData: any = null;
    public is1stProjectionInvoked: boolean = false;

    constructor(private httpClient: HttpClient, private globalService: GlobalService, private sanitizer: DomSanitizer) {
        console.debug('ApiService().constructor()');
    }

    private setHeaders(method: string, url: string) {
        if ((environment.calculator.apiKey == null || environment.calculator.apiKey === undefined) || (environment.calculator.clientId == null || environment.calculator.clientId === undefined)) {
            return;
        }

        let tMethod = method;
        let tDateStamp = new Date();
        let tClientApiKey = environment.calculator.apiKey;
        let tUrl = url;

        let tContentType = (tMethod == 'GET') ? '' : 'application/json;charset=UTF-8';
        let ct = tMethod + tUrl + tContentType + tDateStamp.toUTCString().replace('UTC', 'GMT');
        let ctSHA256 = CryptoJS.HmacSHA256(ct, tClientApiKey);
        let ctBase64 = CryptoJS.enc.Base64.stringify(ctSHA256);

        this.headers = new HttpHeaders({
            'X-TW-API-CLIENTID': environment.calculator.clientId,
            'X-TW-DATE': tDateStamp.toUTCString().replace('UTC', 'GMT'),
            'X-TW-SIGNATURE': ctBase64,
            'Content-Type': tContentType
        });
    }

    // calculator PASSED FROM THE RESOLVER
    init() {
        //console.debug('ApiService().init()');
        let tUrl;
        if (environment.switches.useStaticUrlInit) {
            if (this.globalService.clientCalculator) {
                tUrl = (environment.urlsStatic.init).format(this.globalService.clientCalculator.clientCalculator);
            }
            else {
                tUrl = (environment.urlsStatic.init).format(environment.calculator.clientCalculator);
            }
        }
        else {
            if (this.globalService.clientCalculator) {
                tUrl = (environment.urls.base + environment.urls.init).format(this.globalService.clientCalculator.clientCalculator);
            }
            else {
                tUrl = (environment.urls.base + environment.urls.init).format(environment.calculator.clientCalculator);
            }
        }

        //check for re-route API data
        tUrl = tUrl.replace('spendingplanner', 'retspender');
        if (location.origin.includes('post-retirement') || location.origin.includes('retirement-planning')) {
            let apiUri = location.origin + '/api';
            tUrl = tUrl.replace(environment.urls.base, apiUri);
        }
        this.setHeaders('GET', tUrl);
        let isSoA = window.location.href.indexOf('soa') !== -1;
        return this.httpClient.get(tUrl, { headers: this.headers, observe: 'response' }).pipe(catchError((err) => { if (environment.switches.isErrorTrapActive && !isSoA) { this.globalService.isError = true; }; return this.handleError(err); }));
    }

    checkCalc(): boolean {
        let areEqual = (deepEqual(this.currentDataObj, this.globalService.clientModel.member));
        this.currentDataObj = JSON.parse(JSON.stringify(this.globalService.clientModel.member));

        if (!areEqual) {
            this.isCalculating = true;
            console.debug('ApiService().checkCalc() --> data change found');
        }

        return !areEqual;
    }

    // CREATED JUST IN CASE WE NEED THE MEMBER CHECK THAT WON'T TRIGGER THE ROUND SPINNED TO DISPLAY
    getDirtyMember(): any {
        //check if data model has changed against one in memory. This check is done to see if new calcuation call is required.
        let areEqual = (deepEqual(this.currentDataObj, this.globalService.clientModel.member));
        if (!areEqual) {
            return JSON.parse(JSON.stringify(this.globalService.clientModel.member));
        }
        else {
            return null;
        }
    }

    calc(data: any) {
        if (this.globalService.isInvalid) {
            //console.debug('[] - CANCELLING CALC - INVALID INPUT DETECTED');
            return;
        }

        //set current data object
        this.currentDataObj = data;

        //this.clientEnvironment = obj;
        let tUrl = (environment.switches.useDummyResults) ? environment.urlsStatic.apiResponse : (environment.urls.base + environment.urls.calc).format(environment.calculator.clientCode, environment.calculator.clientCalculator);
        if (environment.switches.isUnitTesting) {
            tUrl = tUrl.replace('assets/', '');
        }
        //check for re-route API data
        tUrl = tUrl.replace('targetplanner', 'retspender');
        if (location.origin.includes('post-retirement') || location.origin.includes('retirement-planning')) {
            let apiUri = location.origin + '/api';
            tUrl = tUrl.replace(environment.urls.base, apiUri);
        }
        if (environment.switches.useDummyResults) {
            this.setHeaders('GET', tUrl);
            return this.httpClient.get(tUrl, { headers: this.headers, observe: 'response' })
                .pipe(delay(5000))
                .pipe(catchError((err) => {
                    if (environment.switches.isErrorTrapActive) {
                        this.globalService.isError = true;
                    }
                    return this.handleError(err);
                }))
                .pipe(finalize(() => {
                    console.debug('ApiService().calc() --> finalize');
                    this.isCalculating = false;
                }));
        }
        else {
            this.setHeaders('POST', tUrl);
            let isSoA = window.location.href.indexOf('soa') !== -1;

            return this.httpClient.post(tUrl, { 'current': data }, { headers: this.headers, observe: 'response' }).pipe(catchError((err) => { if (environment.switches.isErrorTrapActive && !isSoA) { this.globalService.isError = true; }; return this.handleError(err); })).pipe(finalize(() => { console.debug('ApiService().calc() --> finalize'); this.isCalculating = false; }));
        }
    }

    private _lifeExpectancy(tUrl: string, requestData: any) {
        console.debug('ApiService().lifeExpectancy()');

        // let tUrl = (environment.urls.lifeexpectancy).format(environment.calculator.clientCode, environment.calculator.clientCalculator);
        // let member = this.globalService.clientModel.members.current;

        // if (!this.globalService.clientModel.lifeExpectancy) return;

        // let ageNow = member.people[0].age;
        // if (this.globalService.clientCalculator.clientCalculator === "targetplanner") {
        //     ageNow = member.people[0].ageR;
        // }

        // let requestData: any = {
        //     "yearNow": new Date().getFullYear(),
        //     "ageNow": ageNow,
        //     "gender": member.people[0].gender,
        //     "liveToAge": member.targetAge
        // };

        // let requestDataJSON = JSON.stringify(requestData);

        // if (this.previouslifeExpectancyRequestData && requestDataJSON === JSON.stringify(this.previouslifeExpectancyRequestData)) {
        //     return;
        // }

        // this.previouslifeExpectancyRequestData = JSON.parse(requestDataJSON);

        this.isCalculating = true;

        //sanitize
        try {
            parseInt(requestData.yearNow);
            parseInt(requestData.ageNow);
            parseInt(requestData.gender);
            parseInt(requestData.liveToAge);
        } catch (e) {
            console.error('ApiService().lifeExpectancy() --> parse');
            this.globalService.isError = true;
            return;
        }

        this.setHeaders('POST', tUrl);
        return this.httpClient.post(tUrl, requestData, { headers: this.headers, observe: 'response' })
            .pipe(catchError((err) => { if (environment.switches.isErrorTrapActive) { this.globalService.isError = true; } return this.handleError(err); }))
            .pipe(finalize(() => {
                console.debug('ApiService()._lifeExpectancy() --> finalize');
                this.isCalculating = false;
            }));
    }

    getLifeexpectancy() {

        if (this.globalService.isInvalid) {
            return;
        }

        let tUrl = (environment.urls.lifeexpectancy).format(environment.calculator.clientCode, environment.calculator.clientCalculator);
        if (location.origin.includes('post-retirement') || location.origin.includes('retirement-planning')) {
            let apiUri = location.origin + '/api';
            tUrl = tUrl.replace(environment.urls.base, apiUri);
        }

        let member = this.globalService.clientModel.members.current;

        if (!this.globalService.clientModel.lifeExpectancy) return;

        let ageNow = member.people[0].age;
        if (this.globalService.clientCalculator.clientCalculator === "targetplanner") {
            ageNow = member.people[0].ageR;
        }

        let requestData: any = {
            "yearNow": new Date().getFullYear(),
            "ageNow": ageNow,
            "gender": member.people[0].gender,
            "liveToAge": member.targetAge
        };

        let requestDataJSON = JSON.stringify(requestData);

        if (this.previouslifeExpectancyRequestData && requestDataJSON === JSON.stringify(this.previouslifeExpectancyRequestData)) {
            return;
        }

        this.previouslifeExpectancyRequestData = JSON.parse(requestDataJSON);

        this._lifeExpectancy(tUrl, requestData).subscribe((resp: any) => {
            if (resp.body) {
                let results = resp.body;
                this.globalService.clientModel.lifeExpectancy = results;
            }
        });
    }

    randomInteger(min, max): number {
        return Math.floor(Math.random() * (max - min + 1)) + min;
    }

    recalculate() {
        //console.debug('\n\n[>] recalculate()');

        if (this.globalService.isInvalid) {
            return;
        }

        if (this.checkCalc()) {
            this.calc(this.currentDataObj).subscribe((response: any) => {
                if (response.body) {
                    let results = response.body;

                    if (environment.switches.useDummyResults) {
                        results = JSON.parse(JSON.stringify(this.globalService.clientModel.results));
                    }

                    if (environment.switches.useRandomizedChartData) {
                        let dummyBachartData = JSON.parse(JSON.stringify(this.globalService.clientModel.results.current.deterministic.spending));
                        let newDummyBachartData = [];
                        let otherSpendings = this.globalService.clientModel.members.current.spending.goals.filter((item) => item.tag === 'Other');

                        let fromAge = parseInt(this.globalService.clientModel.members.current.people[0].ageR);
                        let toAge = parseInt(this.globalService.clientModel.members.current.targetAge);
                        let startYear = new Date().getFullYear();
                        let duration = 51;

                        for (let i = 0, len = 2; i < len; i++) {
                            let spendingItem = dummyBachartData[i];
                            spendingItem['item'].fromAge = fromAge;
                            spendingItem['item'].toAge = toAge;
                            spendingItem.projection = [];
                            for (let i2 = 0, len2 = (duration); i2 < len2; i2++) {
                                let projectionItem = {
                                    "period": 0,
                                    "indexation": 1,
                                    "amount": this.randomInteger(10000, 50000),
                                    "t": 0,
                                    "year": startYear + i2,
                                    "age": fromAge + i2,
                                    "intAge": fromAge + i2,
                                    "globalPeriod": 0.32,
                                    "amountPV": this.randomInteger(10000, 50000)
                                };
                                spendingItem.projection[i2] = projectionItem;
                            }
                            newDummyBachartData.push(spendingItem);
                        }
                        if (otherSpendings.length > 0) {

                            let dummyBaselineResultSpendingItem = dummyBachartData[0];
                            for (let i3 = 0, len3 = otherSpendings.length; i3 < len3; i3++) {
                                let otherSpending = otherSpendings[i3];
                                let item = JSON.parse(JSON.stringify(dummyBaselineResultSpendingItem));
                                item['item'].name = otherSpending.name;
                                item['item'].fromAge = fromAge;
                                item['item'].toAge = toAge;
                                item['item'].custom = otherSpending.custom;
                                item.projection = [];
                                item['item'].tag = "Other";
                                for (let i4 = 0, len4 = (duration); i4 < len4; i4++) {
                                    let projectionItem = {
                                        "period": 0,
                                        "indexation": 1,
                                        "amount": this.randomInteger(10000, 50000),
                                        "t": 0,
                                        "year": startYear + i4,
                                        "age": fromAge + i4,
                                        "intAge": fromAge + i4,
                                        "globalPeriod": 0.32,
                                        "amountPV": this.randomInteger(10000, 50000)
                                    };
                                    item.projection[i4] = projectionItem;
                                }
                                newDummyBachartData.push(item);
                            }
                        }

                        // Family support = incomeAssets
                        // Annuity = incomeAnnuities
                        // Property = incomeProperty

                        let newSummaryData = JSON.parse(JSON.stringify(this.globalService.clientModel.results.current.deterministic.summary));
                        newSummaryData.age = fromAge;
                        newSummaryData.intAge = fromAge;
                        newSummaryData.year = startYear;
                        for (let i3 = 0, len3 = (duration); i3 < len3; i3++) {
                            let summaryProjectionItem = {
                                "incomeAnnuities": this.randomInteger(10000, 50000),
                                "incomeProperty": this.randomInteger(10000, 50000),
                                "period": 0.32,
                                "deflatorBOY": 1,
                                "deflatorMOY": 1,
                                "incomeNeeds": 0,
                                "incomeYou": this.randomInteger(10000, 50000),
                                "incomePartner": this.randomInteger(10000, 50000),
                                "socialSecurity": 0,
                                "incomeAssets": this.randomInteger(10000, 50000),
                                "totalIncome": 600000,
                                "gap": 0,
                                "balanceAssets": this.randomInteger(10000, 50000),
                                "balanceProperty": this.randomInteger(10000, 50000),
                                "t": 0,
                                "year": startYear + i3,
                                "age": fromAge + i3,
                                "intAge": fromAge + i3,
                                "globalPeriod": 0.32,
                                "indexation": 1
                            };
                            newSummaryData.projection[i3] = summaryProjectionItem;
                        }

                        let newOtherIncomeProjections = JSON.parse(JSON.stringify(this.globalService.clientModel.results.current.deterministic.people[0].otherIncomeProjections));
                        for (let i4 = 0, len4 = newOtherIncomeProjections.length; i4 < len4; i4++) {
                            let otherIncomeProjection = newOtherIncomeProjections[i4];
                            for (let i5 = 0, len5 = (duration); i5 < len5; i5++) {
                                let newOtherIncomeProjection = {
                                    "period": 0,
                                    "taxableAmount": 0,
                                    "nonTaxableAmount": 0,
                                    "taxableAmountPV": this.randomInteger(10000, 50000),
                                    "nonTaxableAmountPV": this.randomInteger(10000, 50000),
                                    "t": 0,
                                    "year": startYear + i5,
                                    "age": fromAge + i5,
                                    "intAge": fromAge + i5,
                                    "globalPeriod": 0.32,
                                    "indexation": 1
                                };
                                otherIncomeProjection.projection[i5] = newOtherIncomeProjection;
                            }
                            newOtherIncomeProjections[i4] = otherIncomeProjection;
                        }

                        results['current']['deterministic']['people'][0].otherIncomeProjections = newOtherIncomeProjections;
                        results['current']['deterministic']['summary'] = newSummaryData;
                        results['current']['deterministic']['spending'] = newDummyBachartData;

                        // NEED TO BE REMOVED OR COMMENTED EVENTUALLY
                        // JUST TEMPORARY UNTIL THE RIGHT CHART DATA IS RETURNED FROM PROJECT
                        //console.debug("\n\n[] results['current']['deterministic']['spending']", results['current']['deterministic']['spending']);
                        //console.debug("[] results['current']['deterministic']['people'][0].otherIncomeProjections", results['current']['deterministic']['people'][0].otherIncomeProjections);
                        //console.debug("[] results['current']['deterministic']['summary']", results['current']['deterministic']['summary']);
                    }

                    this.globalService.clientModel.results = results;
                    this.globalService.triggerGenericEvent(new GenericEvent(enum_GenericEvents.UPDATE_CHARTDATA_FROM_RESULTS));

                }
            });
        }
    }

    handleError(error: HttpErrorResponse) {
        console.debug('ApiService().handleError()');
        //globalService.isError = true;

        if (error.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            console.error('An error occurred:', error.error.message);
        } else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            console.error(
                `Backend returned code ${error.status}, ` +
                `body was: ${error.error}`);
        }

        switch (error.status) {
            case 403:
                return throwError(enum_ErrorMessages.ERRORMESSAGE_403);
            case 404:
                return throwError(enum_ErrorMessages.ERRORMESSAGE_404);
            case 500:
                return throwError(enum_ErrorMessages.ERRORMESSAGE_500);
            default:
                return throwError(`${error.status} - ${error.error}`);
        }
    }
}
