import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { ActivatedRoute, RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { D3Service } from 'd3-ng2-service'; // <-- import statement
import { NgxBootstrapSliderModule } from 'ngx-bootstrap-slider';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import './_interfaces/constraint.interface';
import { ApiService } from './_services/api.service';
import { GlobalService } from './_services/global.service';

const isSpendingPlanner: boolean =
    (window.location.hostname === 'localhost') || // local
    (window.location.hostname === 'post-retirement-evaluation-tool-uat.hsbc.com.hk') || //uat
    (window.location.hostname === 'post-retirement-evaluation-tool.hsbc.com.hk'); //prod

@NgModule({
    declarations: [
        AppComponent,
        HomeComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        HttpClientModule,
        NgxBootstrapSliderModule,
        BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
        NgbModule,
        RouterModule.forRoot([
            { path: '', redirectTo: isSpendingPlanner ? 'spendingplanner' : 'targetplanner', pathMatch: 'full' },
            // { path: '', redirectTo: 'targetplanner', pathMatch: 'full' },
            { path: 'spendingplanner', loadChildren: () => import('./spendingplanner/app.module').then(m => m.SpendingPlanner_Module) },
            { path: 'targetplanner', loadChildren: () => import('./targetplanner/app.module').then(m => m.TargetPlanner_Module) },
            { path: '**', redirectTo: isSpendingPlanner ? 'spendingplanner' : 'targetplanner' }
        ], { relativeLinkResolution: 'legacy' })
    ],
    providers: [
        ApiService,
        GlobalService,
        {
            provide: APP_INITIALIZER,
            useFactory: (globalService: GlobalService) => () => { globalService.isLoadingApp = true; },
            multi: true,
            deps: [GlobalService]
        },
        D3Service
    ],
    bootstrap: [AppComponent]
})
export class AppModule {

    constructor(private globalService: GlobalService, private route: ActivatedRoute) {
        console.debug('AppModule().constructor()');

        this.route.queryParams.subscribe(params => {
            let lang = params['lang'];
            if (lang != null && lang != undefined) {
                switch (lang) {
                    case 'en':
                    case 'zh':
                        this.globalService.currentLanguage = lang;
                        break;
                }
            }
        });
    }
}
