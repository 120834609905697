export class NavigationModel {

    currentPageTitle: string = "";
    currentTabTitle: string = "contributions";
    currentTabTitle2: string = "";

    isDisclaimerAccepted: boolean = false;
    isNavigationEnabled: boolean = true;
    currentStep: number = -1;
    previousStep: number = -1;
    isSidemenuShown: boolean = false;
    routeIndexes: any = null;
    isDesktop: boolean = true;
    browser: string = null;
    os: string = null;
    userAgentInfo: any = null;
    responsiveSize: string = null;

    //
    isConfirmAllowed: boolean = false;

    constructor() {}

}
